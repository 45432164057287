import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Hero = () => (
    <div className="hero-wrapper" style={{ display: "grid" }}>
      <StaticImage
        style={{
            gridArea: "1/1", objectFit: "scale-down",
        }}
        layout="fullWidth" 
        loading="eager" 
        aspectRatio={3}
        alt="Our team will help"
        // Original Photo by Austin Distel Unsplash  
        src={
          "../images/home-header.jpg"
        }
        formats={["auto", "jpg", "avif"]}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          textAlign: "center",
          display: "grid",
          marginTop: "-120px"
        }}
      >
        <h1 className="hero-h1">
          Stress-free IT
        </h1>

      </div>
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
          zIndex:10,
        }}
      >
        <div>
          <Link to="/contact/main/" className={"btn btn--request"}>REQUEST INFO</Link>
        </div>

      </div>

      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          textAlign: "center",
          display: "grid",
          marginTop: "175px"
        }}
      >
        <h2 className="hero-h1" style={{color:"white"}}>
            IT services and support
        </h2>
      </div>

    </div>
  )
  
  export default Hero